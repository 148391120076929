import AuthApi from '@/api/auth.api';
import i18n from '@/i18n';
import router from '@/router';
import ErrorHandler from '@/helpers/errors';
import { deserializer, serializer } from '@/helpers/api';
import { PhoneFormatterAdapter } from '@takamol/nawa-library/src/helpers/phone-formatter';

const mainState = {
  loginForm: {},
  registerForm: {},
  resetPasswordForm: {},
  loading: false,
  invitationLoading: false,
  otpLoading: false,
  errors: [],
};

const mainGetters = {
  getErrors: (store) => store.errors,
  loginForm: (store) => store.loginForm,
  registerForm: (store) => store.registerForm,
  resetPasswordForm: (store) => store.resetPasswordForm,
  loading: (store) => store.loading,
  invitationLoading: (store) => store.invitationLoading,
  otpLoading: (store) => store.otpLoading,
};

export const mutations = {
  setLoading: (store, value) => {
    store.loading = value;
  },
  setInvitationLoading: (store, value) => {
    store.invitationLoading = value;
  },
  setOtpLoading: (store, value) => {
    store.otpLoading = value;
  },
  setLoginForm: (store, form) => {
    store.loginForm = {
      ...store.loginForm,
      ...form,
    };
  },
  setRegisterForm: (store, form) => {
    store.registerForm = {
      ...store.registerForm,
      ...form,
    };
  },
  setResetPasswordForm: (store, form) => {
    store.resetPasswordForm = {
      ...store.resetPasswordForm,
      ...form,
    };
  },
  setErrors: (store, errors) => {
    store.errors = errors;
  },
  resetRegisterForm: (store) => {
    store.registerForm = {};
  },
};

export const actions = {
  invitationRedirect: async (_, { user, token }) => {
    const routerData = {
      params: {
        readable: true,
        nid: user.nationalId,
        dob: user.birthDateHijri,
        inviteId: user.id,
      },
    };
    if (user.registrationFinished && router.currentRoute.name !== 'Login') routerData.name = 'Login';
    if (!user.registrationFinished && router.currentRoute.name !== 'Registration') routerData.name = 'Registration';
    if (!router.currentRoute.query.requestToken) {
      router.push({ ...routerData, query: { requestToken: token } });
    } else {
      await router.replace(routerData);
      await router.replace({ ...routerData, query: { requestToken: token } });
    }
  },
  getInvitationsInfo: async ({ commit, dispatch }, token) => {
    commit('setInvitationLoading', true);
    try {
      const res = await AuthApi.invitationsData(token);
      const { user } = await deserializer(res.data);
      await dispatch('invitationRedirect', { user, token });
    } catch (e) {
      const { error } = ErrorHandler.parseFormErrors(e);
      commit('setErrors', error);
    } finally {
      setTimeout(() => {
        commit('setInvitationLoading', false);
      }, 300);
    }
  },
  loginIndividual: async ({ commit, state }) => {
    const { nid, password } = state.loginForm;
    const data = serializer({
      password,
      national_id: nid,
      space: 'user',
    }, 'login');
    commit('setLoading', true);
    try {
      return await AuthApi.loginIndividual(data);
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    } finally {
      commit('setLoading', false);
    }
  },
  loginIndividualByOAuthCode: async ({ commit, dispatch }, data) => {
    try {
      await AuthApi.loginIndividualByOAuthCode(data);
      return await dispatch('user/getUserProfile', { useLoading: false }, { root: true });
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    } finally {
      commit('setLoading', false);
    }
  },
  logoutIndividual: async ({ commit }, needRedirect = true) => {
    try {
      AuthApi.logoutIndividual(serializer({}, 'logout'));
    } catch (e) {
      console.warn(e);
    }
    if (needRedirect) {
      await router.replace({ name: 'Home' });
    }
    commit('user/setProfile', null, { root: true });
    commit('user/setAccount', null, { root: true });
    commit('entities/setEntitiesLoaded', false, { root: true });
    commit('entities/resetEntitiesState', false, { root: true });
    commit('userNotifications/resetUserRequestsCount', null, { root: true });
  },
  registerIndividual: async ({ commit, state, dispatch }) => {
    const {
      nid,
      password,
      passwordConfirm,
      birthday,
      phone,
      title,
      email,
    } = state.registerForm;
    const data = serializer({
      password,
      email,
      title,
      password_confirmation: passwordConfirm,
      first_name: 'John',
      last_name: 'Doe',
      phone: PhoneFormatterAdapter.fromUIToServer(phone),
      national_id: nid,
      birth_date_hijri: `${birthday.year}${birthday.month}${birthday.day}`,
    }, 'registration');

    commit('setLoading', true);
    try {
      await AuthApi.registerIndividual(data);
      return await dispatch('user/getUserProfile', { useLoading: false }, { root: true });
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    } finally {
      commit('setLoading', false);
    }
  },
  checkRegistration: async ({ commit, state }) => {
    const { nid, birthday } = state.registerForm;
    const data = serializer({
      nationalId: nid,
      birthDateHijri: `${birthday.year}${birthday.month}${birthday.day}`,
    }, 'registration');

    commit('setLoading', true);
    try {
      return await AuthApi.checkRegistration(data);
    } catch (e) {
      if (!e.response) {
        return { error: i18n.t('Network Error') };
      }
      return ErrorHandler.parseFormErrors(e);
    } finally {
      commit('setLoading', false);
    }
  },
  checkRegistrationOtp: async ({ commit, state }, code) => {
    const { nid } = state.registerForm;
    const data = serializer({
      otp: code,
      nationalId: nid,
    }, 'otp');

    commit('setOtpLoading', true);
    try {
      return await AuthApi.checkRegistrationOtp(data);
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    } finally {
      commit('setOtpLoading', false);
    }
  },
  checkPasswordLogin: async ({ commit, state }) => {
    const { nid, birthday } = state.resetPasswordForm;
    const data = serializer({
      nationalId: nid,
      birthDateHijri: `${birthday.year}${birthday.month}${birthday.day}`,
      action: 'reset-password',
    }, 'reset-password');

    commit('setLoading', true);
    try {
      return await AuthApi.checkPasswordLogin(data);
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    } finally {
      commit('setLoading', false);
    }
  },
  checkPasswordOtp: async ({ commit }, code) => {
    const data = serializer({
      otp: code,
    }, 'otp');

    commit('setOtpLoading', true);
    try {
      return await AuthApi.checkPasswordOtp(data);
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    } finally {
      commit('setOtpLoading', false);
    }
  },
  updatePassword: async ({ commit }, password) => {
    const data = {
      password,
    };
    commit('setLoading', true);
    try {
      return await AuthApi.updatePassword(data);
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    } finally {
      commit('setLoading', false);
    }
  },
  checkLoginOtp: async ({ commit, dispatch }, { code, redirect }) => {
    const data = serializer({
      otp: code,
    }, 'otp');

    commit('setOtpLoading', true);
    try {
      await AuthApi.checkLoginOtp(data);
      if (redirect) return {};
      return await dispatch('user/getUserProfile', { useLoading: false }, { root: true });
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    } finally {
      commit('setOtpLoading', false);
    }
  },
  verifyPhone: async ({ state }) => {
    const { phone, nid } = state.registerForm;
    const data = serializer({
      phone: PhoneFormatterAdapter.fromUIToServer(phone),
      nationalId: nid,
    }, 'phone');

    try {
      return await AuthApi.verifyPhone(data);
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    }
  },
  verifyPhoneOtp: async ({ commit, state }, code) => {
    const { nid } = state.registerForm;
    const data = serializer({
      national_id: nid,
      otp: code,
    }, 'otp');
    commit('setOtpLoading', true);
    try {
      return await AuthApi.verifyPhoneOtp(data);
    } catch (e) {
      return ErrorHandler.parseFormErrors(e);
    } finally {
      commit('setOtpLoading', false);
    }
  },
};

export default {
  namespaced: true,
  state: mainState,
  getters: mainGetters,
  mutations,
  actions,
};
