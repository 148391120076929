import {
  image,
  size,
  mimes,
  required,
  min_value,
  double,
  integer,
  numeric,
} from 'vee-validate/dist/rules';
import uniqBy from 'lodash.uniqby';
import i18n from '@/i18n';
import { ENTITY_BANNED_WORDS } from '@/constants';

const validateResolution = (file, width, height) => {
  const URL = window.URL || window.webkitURL;
  return new Promise(((resolve) => {
    const imageObj = new Image();
    imageObj.onerror = () => resolve(false);
    imageObj.onload = () => resolve(imageObj.width >= width && imageObj.height >= height);
    imageObj.src = URL.createObjectURL(file);
  }));
};

const validateImageResolution = (files, _a) => {
  const { width } = _a; const
    { height } = _a;
  const list = [];
  const regex = /\.(jpg|svg|jpeg|png|bmp|gif|webp)$/i;
  files = Array.isArray(files) ? files : [files];
  files.forEach((file) => {
    if (!regex.test(file.name)) {
      // if file is not an image, reject.
      return Promise.resolve(false);
    }
    return list.push(file);
  });
  return Promise.all(list
    .map((file) => validateResolution(
      file,
      width,
      height,
    ))).then((values) => values.every((v) => v));
};

export const RULES = {
  required: { ...required },
  min_value: { ...min_value },
  size: { ...size },
  image: { ...image },
  mimes: { ...mimes },
  double: { ...double },
  integer: { ...integer },
  numeric: { ...numeric },
  validate: {
    validate: (value) => Boolean(value),
  },
  notTrue: {
    validate: (value) => !value,
  },
  values_match: {
    params: ['target'],
    validate(value, { target }) {
      return value === target;
    },
  },
  arabic: {
    validate: (value) => /[\u0600-\u06FF\u0750-\u077F]/.test(value),
  },
  english: {
    validate: (value) => /^[0-9a-zA-Z\u0080-\u024F]+$/i.test(value),
  },
  minDimensions: {
    params: [
      {
        name: 'width',
        cast: (value) => Number(value),
      },
      {
        name: 'height',
        cast: (value) => Number(value),
      },
    ],
    validate: validateImageResolution,
  },
  nidOrCr: {
    validate: (value) => /(?=^[1].*$)(?=^\d{10}$)/.test(value),
  },
  maxValue: {
    params: ['target'],
    validate(value, { target }) {
      return value <= target;
    },
  },
  lessThan: {
    params: ['maxValue'],
    validate(value, { maxValue }) {
      return Boolean(Number(maxValue) > Number(value));
    },
  },
  moreThanZero: {
    validate: (value) => Boolean(Number(value) > 0),
  },
  dateRequired: {
    validate: (value) => Boolean(value.day && value.year && value.month),
  },
  nidNotOwner: {
    validate: () => Boolean(false),
  },
  capitalValidation: {
    params: ['capital'],
    validate: (value, { capital }) => (capital * 0.1) >= value,
  },
  iban: {
    validate: (value) => value.length === 24,
  },
  bank700number: {
    validate: (value) => value.length === 10 && value.startsWith(7),
  },
  firstIsDigit: {
    validate: (value) => /^([0-9]{1}:?){1}/.test(value),
  },
  digitsOrFloat: {
    validate: (value) => /^(\d+(\.)\d+)$/.test(value) || /^(\d+)$/.test(value),
  },
  positiveDouble: {
    validate: (value) => /\d+(\.)\d+/.test(value),
  },
  positiveDoubleMin: {
    params: ['num'],
    validate: (value, { num }) => Math.floor(
      String(value).replace(',', '.'),
    ) >= num,
  },
  maxLength: {
    params: ['length'],
    validate(value, { length }) {
      value = value.toString();
      return value.length <= Number(length);
    },
  },
  minLengthCustom: {
    params: ['length'],
    validate(value, { length }) {
      value = value.toString();
      return value.length >= Number(length);
    },
  },
  bannedWords: {
    validate: (value) => !ENTITY_BANNED_WORDS.some((w) => value.split(' ').includes(w)),
    message: (_, values) => {
      // eslint-disable-next-line no-underscore-dangle
      const inputs = values._value_.split(' ') || [];
      const bannedWords = ENTITY_BANNED_WORDS.reduce((acc, word) => {
        if (inputs.find((item) => item === word)) {
          acc.push(word);
        }
        return uniqBy(acc);
      }, []);
      return i18n.t('validations.entityBannedWords', { words: bannedWords.join(' ') });
    },
  },
};
