<template>
  <div class="d-flex align-items-center">
    <div
      v-click-outside="closeMobileMenu"
      class="user-menu"
      :class="{ open: isOpen }"
      @keyup.enter="openMenu"
      @mouseenter="openMenu"
      @mouseleave="closeMenu"
    >
      <button
        id="menu"
        type="button"
        class="user-menu-profile"
        @click.stop="handleProfileClick"
      >
        <svg
          class="user-menu-ico"
          viewBox="0 0 32 32"
          color="#22a88f"
        >
          <icon-profile class="user-menu-ico" />
        </svg>
        <router-link
          id="profile"
          :to="{name: 'UserProfile'}"
          tag="a"
          class="user-menu-label"
        >
          {{ this.$t('space.profile') }}
        </router-link>
      </button>
      <div class="user-menu-wrap">
        <span
          id="close-menu"
          class="mobile-btn"
          @click.stop="closeMobileMenu"
        >
          <span class="line" />
          <span class="line" />
        </span>
        <ul class="user-menu-list">
          <li
            v-if="showGoToProfile && $screen['mobile-']"
            class="user-menu-item"
          >
            <div
              class="user-menu-btn"
              @click.stop="closeMobileMenu"
            >
              <router-link
                id="menu-profile"
                :to="{name: 'UserProfile'}"
                tag="a"
                class="user-menu-label"
              >
                {{ this.$t('space.profile') }}
              </router-link>
            </div>
          </li>
          <li
            class="user-menu-item"
          >
            <div
              class="user-menu-btn"
              @click.stop="closeMobileMenu"
            >
              <router-link
                id="menu-user-space"
                :to="{name: 'UserSpace'}"
                tag="a"
                class="user-menu-label"
              >
                {{ this.$t('space.userSpace') }}
              </router-link>
            </div>
          </li>
          <li class="user-menu-item">
            <mq-layout mq="mobile-">
              <div class="user-menu-profile user-menu-landing">
                <router-link
                  id="home"
                  :to="{name: 'Home'}"
                  tag="a"
                  class="user-menu-label"
                >
                  {{ this.$t('general.landingPage') }}
                </router-link>
              </div>
            </mq-layout>
          </li>
          <li class="user-menu-item">
            <button
              id="menu-logout"
              type="button"
              class="user-menu-btn"
              @click="logoutIndividual"
            >
              <span class="user-menu-label">{{ this.$t('space.logout') }}</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <mq-layout
      mq="tablet+"
    >
      <div class="user-menu-profile user-menu-landing">
        <router-link
          id="landing-page"
          :to="{name: 'Home'}"
          tag="a"
          class="user-menu-label"
        >
          {{ this.$t('general.landingPage') }}
        </router-link>
      </div>
    </mq-layout>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'UserMenu',
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    showGoToProfile() {
      return this.$route.name !== 'UserProfile';
    },
  },
  methods: {
    ...mapActions({
      logoutIndividual: 'auth/logoutIndividual',
    }),
    openMenu() {
      if (!this.$screen['mobile-']) {
        this.isOpen = true;
      }
    },
    closeMobileMenu() {
      this.isOpen = false;
    },
    closeMenu() {
      if (!this.$screen['mobile-']) {
        this.isOpen = false;
      }
    },
    handleProfileClick() {
      if (this.$screen['mobile-']) {
        this.isOpen = true;
      } else if (this.$route.name !== 'UserProfile') {
        this.$router.push({ name: 'UserProfile' });
      }
    },
  },
};
</script>

<style src="./styles.scss" lang="scss"/>
