const entityProfileLogo = `entityProfile {
  logo {
    origin {
      attachedAt
      url: path
      uid
    }
    x100 {
      attachedAt
      url: path
      uid
    }
    x200 {
      attachedAt
      url: path
      uid
    }
    x300 {
      attachedAt
      url: path
      uid
    }
    x500 {
      attachedAt
      url: path
      uid
    }
  }
}`;

const documentData = `{
  uid
}`;

const areasOfLocations = `
areasOfActivity {
  cities {
    arTitle
    enTitle
    regionCode
    uid
  }
  cityUids
  entityId
  region {
    arTitle
    cities {
      arTitle
      enTitle
      regionCode
      uid
    }
    code
    enTitle
  }
  regionCode
  type
}`;

export const FIND_ENTITY_BY_ID_FOR_PROFILE = `query FindEntityByIDForProfile($id: Int!) {
  entityProfile(id: $id) {
    ${entityProfileLogo}
    ${areasOfLocations}
    entityNationalAddress {
      additionalNumber
      buildingNumber
      postCode
      streetName
    }
    licenseNumber700
    foundFor
    memberRole
    unifiedNumber700
    id
    type
    entityUid
    nameArabic
    nameEnglish
    acceptedAt
    registrationDateHijri
    typeOfBenefits
    entityLicenseNumber
    membershipType
    city {
      arTitle
      enTitle
    }
    region {
      arTitle
      enTitle
    }
    secondSubClassificationId
    classificationDetails {
      mainClassificationArTitle
      firstSubClassificationArTitle
      secondSubClassificationArTitle
      departmentArTitle
    }
    activities {
      arTitle
    }
    goals {
      ... on CommonGoal {
        arTitle
      }
      ... on FamilyTrustGoal {
        arTitle
        enTitle
      }
    }
    isNama
    entityLicenses ${documentData}
    bankCertificates ${documentData}
    eligibilityStudyFiles ${documentData}
    entityPolicies ${documentData}
    establishmentDecisions ${documentData}
    migrationDelegateLetters ${documentData}
    supportingDocuments ${documentData}
  }
}`;
export const ENTITY_PROFILE_INFO = `query FindEntityProfile($id: Int!) {
  entityProfile(id: $id) {
    ${entityProfileLogo}
    unifiedNumber700
    id
    type
    entityUid
    nameArabic
    nameEnglish
    acceptedAt
    registrationDateHijri
    typeOfBenefits
    entityLicenseNumber
    membershipType
    memberRole
    city {
      arTitle
      enTitle
    }
    region {
      arTitle
      enTitle
    }
    classificationDetails {
      mainClassificationArTitle
      firstSubClassificationArTitle
      secondSubClassificationArTitle
      departmentArTitle
    }
    activities {
      arTitle
    }
    goals {
      ... on CommonGoal {
        arTitle
      }
      ... on FamilyTrustGoal {
        arTitle
      }
    }
    isNama
    entityLicenses {
      uid
    }
    entityPolicies {
      uid
    }
    establishmentDecisions {
      uid
    }
  }
}`;

export const ENTITY_ADDITIONAL_INFO = `query FindEntityAdditionalInfo($entityId: Int!) {
  membershipConditions(entityId: $entityId) {
    membershipConditions {
      type
    }
  }
  publicMemberships(unitId: $entityId) {
    memberships {
      id
      foundingMember
      familyRelation {
        enTitle
        arTitle
      }
      specialized
      member {
        firstName
        fatherName
        grandfatherName
        lastName
        nationalId
        birthDateHijri
        absherPhone
        occupation
        previousExperience
        educationalLevel
        employmentPlace
        educationSpecialization
      }
      position {
        arTitle
        enTitle
      }
      role
    }
  }
}`;

export const FETCH_ENTITY_CONTACTS = `query FindEntityContacts(
  $entityId: Int!,
) {
  findEntityContactByEntityId(entityId: $entityId) {
    createdAt
    email
    entityId
    facebookAccount
    id
    instagramAccount
    linkedinAccount
    phoneNumber
    snapchatAccount
    telephoneNumber
    twitterAccount
    unifiedNumber
    updatedAt
    website
  }
}`;

export const FETCH_ENTITY_EMPLOYEES = `query FetchEntityEmployees(
  $entityId: Int!,
  $page: Int!,
  $size: Int!,
  $active: Boolean,
){
  listProfileEmployeeRequests(
    page: $page,
    size: $size,
    unitId: $entityId,
    unitType: Entity,
    status: [accepted],
    active: $active,
    ) {
      employeeRequests {
        id
        active
        employee {
          firstName
          lastName
          fatherName
          grandfatherName
          nationalId
          occupation
          birthDateHijri
          previousExperience
          educationSpecialization
          educationalLevel
          nationality {
            arTitle
            enTitle
            uid
          }
          absherPhone
        }
        position
        salary
        position
        positionExperienceYears
        previousExperience
        totalExperience
        customPosition
      }
      meta {
        recordsCount
        size
        pageCount
        page
      }
    }
}`;

export const CREATE_ENTITY_CONTACT = `mutation CreateEntityContact(
  $entityContactCreateInput: EntityContactCreateInput!
) {
  createEntityContact (entityContactCreateAttributes:  $entityContactCreateInput){
    createdAt
    email
    entityId
    facebookAccount
    id
    instagramAccount
    linkedinAccount
    phoneNumber
    snapchatAccount
    telephoneNumber
    twitterAccount
    unifiedNumber
    updatedAt
    website
  }
}`;

export const UPDATE_ENTITY_AVATAR = `mutation UpdateEntityAvatar(
  $entityProfileUpdateInput: EntityProfileUpdateInput!
) {
  updateEntityProfile (entityProfileUpdateAttributes:  $entityProfileUpdateInput){
    entityId
  }
}`;

export const UPDATE_ENTITY_CONTACT = `mutation UpdateEntityContact(
  $entityContactInput: EntityContactInput!
) {
  updateEntityContact (entityContactUpdateAttributes:  $entityContactInput){
    createdAt
    email
    entityId
    facebookAccount
    id
    instagramAccount
    linkedinAccount
    phoneNumber
    snapchatAccount
    telephoneNumber
    twitterAccount
    unifiedNumber
    updatedAt
    website
  }
}`;

export const ISSUE_UNIFIED_NUMBER_700 = `mutation IssueUnifiedNumber700 ($unifiedNumberIssueAttributes: UnifiedNumberIssueInput!) {
  issueUnifiedNumber700(unifiedNumberIssueAttributes: $unifiedNumberIssueAttributes) {
    unifiedNumber700
  }
}`;

export const FETCH_CITIES_FOR_ISSUING_UNIFIED_NUMBER_700 = `query FetchCitiesForIssuingUnifiedNumber700 ($page: Int!, $size: Int!, $name: String,) {
  listUnifiedCities(page: $page, size: $size, name: $name) {
    cities {
      cityCode
      name
    }
  }
}
`;
