<template>
  <div class="dashboard-layout">
    <layout-loading v-if="isLoading" />
    <redirect
      v-if="!isLoading && !isAuthenticated"
      to="Login"
      @hook:created="showWarning"
    />
    <template v-if="!isLoading && isAuthenticated && !routeNotAvailable">
      <Header>
        <user-menu slot="header-left" />
      </Header>
      <div class="dashboard-main">
        <router-view />
      </div>
      <notifications-container />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Header from '@/containers/header/Header.vue';
import UserMenu from '@/containers/user-menu/UserMenu.vue';
import NotificationsContainer from '@/containers/notifications/NotificationsContainer.vue';

export default {
  name: 'DashboardLayout',
  components: {
    UserMenu,
    Header,
    NotificationsContainer,
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'user/isAuthenticated',
      isLoading: 'user/isSessionLoading',
    }),
    routeNotAvailable() {
      return this.$route.matched.some(
        (record) => record.meta.blockProd,
      );
    },
  },
  methods: {
    ...mapActions({
      showNotification: 'toast/showNotification',
    }),
    showWarning() {
      this.showNotification({
        message: this.$t('general.redirectLogin'),
        duration: 4000,
        type: 'warning',
      });
    },
  },
};
</script>

<style src="./styles.scss" lang="scss"/>
